<template>
  <a-table
    :dataSource="data"
    :columns="columns"
    :loading="loading"
    :pagination="{
      hideOnSinglePage: true,
      total: total,
      current: currentPage,
      defaultPageSize: limit,
    }"
    rowKey="id"
    @change="tableChange"
    class="ant-table-striped"
    :rowClassName="
      (record, index) => (record.status == 'LOCKED' ? 'table-striped' : null)
    "
  >
    <template #dueDate="{ text }">
      {{ text ? $filters.formatTime(text, "YYYY/MM/DD") : "" }}
    </template>

    <template #lastLoginTime="{ text }">
      {{ text ? $filters.formatTime(text) : "" }}
    </template>
    <!-- <template #tool="{ text }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="
                    showStatusModal = true;
                    upateStatusI = text;
                    newStatus = list[text].status;
                  "
                  >Status</a
                >
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="openRolesModal(text)">Roles</a>
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click.stop.prevent="showDeleteConfirm(text)"
                  >Delete</a
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template> -->
  </a-table>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";

import { SettingOutlined } from "@ant-design/icons-vue";
import { inject } from "@vue/runtime-core";
const columns = [
  {
    title: "Time",
    dataIndex: "time",
  },
  {
    title: "onTimeCount",
    dataIndex: "onTimeCount",
  },

  {
    title: "advanceTime",
    dataIndex: "advanceTime",
    slots: { customRender: "advanceTime" },
  },
  {
    title: "overdueCount",
    dataIndex: "overdueCount",
    slots: { customRender: "overdueCount" },
  },
  {
    title: "overdueTime",
    dataIndex: "overdueTime",
  },
  {
    title: "postponeCount",
    dataIndex: "postponeCount",
  },
  {
    title: "postponeNum",
    dataIndex: "postponeNum",
  },
  {
    title: "postponeTime",
    dataIndex: "postponeTime",
  },

  // {
  //   title: "...",
  //   key: "tool",
  //   dataIndex: "key",
  //   slots: { customRender: "tool" },
  // },
];
export default {
  components: {
    SettingOutlined,
  },
  props: {
    data: { type: Array },
    loading: { type: Boolean },
  },
  setup(props) {
    const { $msg } = inject("$");
    const v = reactive({
      limit: 20,
      total: 0,
      currentPage: 1,
      page: 1,
    });

    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
    }

    return {
      ...toRefs(v),
      columns,
      tableChange,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <a-empty v-if="data.length == 0" style="margin-top: 100px" />
  <div id="chart" v-else></div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted, watch } from "vue";
import echarts from "@/utils/echarts.js";
import { useQuery, useResult } from "@vue/apollo-composable";

import * as dayjs from "dayjs";
var dayOfYear = require("dayjs/plugin/dayOfYear");
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);

export default {
  props: {
    data: { Type: Array },
  },
  components: {},
  setup(props) {
    const { $msg } = inject("$");

    let now = new Date().getTime();
    watch(
      () => props.data,
      () => {
        echartInit();
      }
    );

    const days = [
      "onTimeCount",
      "advanceCount",
      "advanceTime",
      "overdueCount",
      "overdueTime",
      "postponeCount",
      "postponeNum",
      "postponeTime",
    ];

    let myChart;
    function echartInit() {
      if (!props.data || props.data.length < 1) {
        return;
      }
      let d = [];
      props.data.forEach((e) => {
        d.push([e.onTimeCount, "onTimeCount", e.time]);
        d.push([e.advanceCount, "advanceCount", e.time]);
        d.push([e.advanceTime, "advanceTime", e.time]);
        d.push([e.overdueCount, "overdueCount", e.time]);
        d.push([e.overdueTime, "overdueTime", e.time]);
        d.push([e.postponeCount, "postponeCount", e.time]);
        d.push([e.postponeNum, "postponeNum", e.time]);
        d.push([e.postponeTime, "postponeTime", e.time]);
      });

      if (myChart != null && myChart != undefined) {
        myChart.dispose(); //销毁
      }

      let colors = 1
        ? ["#efefef", "#d8e887", "#8cc569", "#47a042", "#1d6a23"]
        : ["#efefef", "#64b5f6", "#1976d2", "#1565c0", "#0d47a1"];

      let option = {
        grid3D: {},
        tooltip: {},
        xAxis3D: {
          type: "category",
        },
        yAxis3D: {
          type: "category",
        },
        zAxis3D: {},
        visualMap: {
          max: 1e8,
          dimension: "value",
        },
        dataset: {
          dimensions: ["value", "type", { name: "time", type: "ordinal" }],
          source: d,
        },
        series: [
          {
            name: "issue-status",
            type: "bar3D",
            // symbolSize: symbolSize,
            shading: "lambert",
            encode: {
              x: "time",
              y: "type",
              z: "count",
              // tooltip: [0, 1, 2],
            },
          },
        ],
      };

      myChart = echarts.init(document.getElementById("chart"));
      myChart.setOption(option);
      // v.myChart = myChart;
    }

    onMounted(() => {
      echartInit();
    });

    return {};
  },
};
</script>
<style lang="scss" scoped>
#chart {
  height: 850px;
  width: 100%;
  margin: auto;
}
</style>

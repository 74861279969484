<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Your Issues" />
    <a-row type="flex" justify="start" class="mb-20">
      <div class="me-10">
        Dimension:
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="(item, index) in dimensionList" :key="index" @click="dimension = item">
                {{ item }}
              </a-menu-item>
            </a-menu>
          </template>
          <a-button class="dropdown" style="width: 120px">
            {{ dimension }}
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </div>
      <a-row class="me-10" v-if="dimension == 'quarter' || dimension == 'week' || dimension == 'hour'
        ">
        <a-space class="me-10">
          <span class="" style="margin-left: 10px">From:</span>
          <Datepicker v-model="fromTime" class="btn-datepick" :upperLimit="toTime" :lowerLimit="new Date('2019/01/01')"
            :startingView="startingView" :minimumView="startingView" :inputFormat="timeformat" />

          <span style="text-transform: capitalize">{{ dimension }}:</span>
          <input type="number" class="btn-datepick" style="width: 80px" v-model="formNumber"
            :max="dimension == 'hour' ? 24 : 52" :min="dimension == 'hour' ? 0 : 1"
            v-if="dimension == 'week' || dimension == 'hour'" />
          <a-dropdown :trigger="['click']" v-else>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="formNumber = item" :key="item" v-for="item in 4">
                  Q{{ item }}
                </a-menu-item>
              </a-menu>
            </template>
            <a-button class="dropdown">
              Q{{ formNumber }}
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </a-space>
        <a-space>
          <span style="margin-left: 10px">To:</span>
          <Datepicker v-model="toTime" class="btn-datepick" :lowerLimit="fromTime" :startingView="startingView"
            :minimumView="startingView" :inputFormat="timeformat" />
          <span style="text-transform: capitalize">{{ dimension }}:</span>
          <input type="number" class="text-start btn btn-datepick" style="width: 80px" v-model="toNumber"
            :max="dimension == 'hour' ? 24 : 52" :min="dimension == 'hour' ? 0 : 1"
            v-if="dimension == 'week' || dimension == 'hour'" />
          <a-dropdown :trigger="['click']" v-else>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="toNumber = item" :key="item" v-for="item in 4">
                  Q{{ item }}
                </a-menu-item>
              </a-menu>
            </template>
            <a-button class="dropdown">
              Q{{ toNumber }}
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </a-space>
      </a-row>
      <a-row type="flex" justify="start" v-else>
        <a-space class="me-10">
          <span class="fw-bolder fs-sm ms-md-2 me-2">From</span>
          <Datepicker v-model="fromTime" class="btn-datepick" :upperLimit="toTime" :lowerLimit="new Date('2019/01/01')"
            :startingView="startingView" :minimumView="startingView" :inputFormat="timeformat" />
        </a-space>
        <a-space class="dropdown d-flex align-items-center py-1">
          <span class="fw-bolder fs-sm ms-md-2 me-2">To</span>
          <Datepicker v-model="toTime" class="btn-datepick" :lowerLimit="fromTime" :startingView="startingView"
            :minimumView="startingView" :inputFormat="timeformat" />
        </a-space>
      </a-row>

      <a-radio-group v-model:value="value1" button-style="solid" style="margin-left: 20px">
        <a-radio-button style="height: 34px; padding-top: 2px" value="1">
          <TableOutlined /> Table
        </a-radio-button>
        <a-radio-button style="height: 34px; padding-top: 2px" value="2">
          <BarChartOutlined /> Chart
        </a-radio-button>
      </a-radio-group>
    </a-row>

    <div class="mt-30" v-if="list">
      <IssuesTable :data="list" :loading="listloading" v-show="value1 == 1" />
      <IssuesChart :data="list" v-show="value1 != 1" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, inject, computed, watch } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import {
  DownOutlined,
  BarChartOutlined,
  TableOutlined,
} from "@ant-design/icons-vue";
import IssuesTable from "./IssuesTable.vue";
import IssuesChart from "@/components/chart/IssuesChart.vue";
import { useStore } from "vuex";
import Datepicker from "vue3-datepicker";

import * as dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var weekOfYear = require("dayjs/plugin/weekOfYear");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);

export default {
  components: {
    IssuesTable,
    IssuesChart,
    DownOutlined,
    Datepicker,
    BarChartOutlined,
    TableOutlined,
  },

  setup() {
    const { $msg, gql, $filters } = inject("$");

    const v = reactive({
      dimension: "week",
      fromTime: dayjs().subtract(4, "week").toDate(),
      toTime: new Date(),
      formNumber: 1,
      toNumber: 4,
      start: null,
      end: null,
      value1: "1",
    });

    v.start = formatTime(dayjs().subtract(4, "week").toDate(), 1);
    v.end = formatTime(new Date(), 4);

    watch(() => v.fromTime, updateData);
    watch(() => v.formNumber, updateData);
    watch(() => v.toTime, updateData);
    watch(() => v.toNumber, updateData);
    watch(
      () => v.dimension,
      (val) => {
        switch (val) {
          case "year":
            v.limit = 10;
            break;
          case "quarter":
            v.limit = 20;
            break;
          case "month":
            v.limit = 24;
            break;
          case "week":
            v.limit = 53;
            break;

          default:
            break;
        }
        updateData();
      }
    );

    function formatTime(time, number) {
      let timeFactor = `Y${dayjs(time).$y}`;
      let month;
      let day;
      switch (v.dimension) {
        case "quarter":
          timeFactor += `Q${number}`;
          break;
        case "week":
          if (number.length != 2) number = `0${number}`;
          timeFactor += `W${number}`;
          break;
        case "month":
          month = dayjs(time).$M + 1 + "";
          if (month.length != 2) month = `0${month}`;
          timeFactor = `${timeFactor}M${month}`;
          break;
        case "Milestone":
          month = dayjs(time).$M + 1 + "";
          if (month.length != 2) month = `0${month}`;
          timeFactor = `${timeFactor}M${month}`;
          day = dayjs(time).$D + "";
          if (day.length != 2) day = `0${day}`;
          timeFactor = `${timeFactor}D${day}`;
          break;
      }
      return timeFactor;
    }
    async function updateData(params) {
      if (
        v.formNumber > v.toNumber ||
        !dayjs(v.fromTime).isSameOrBefore(dayjs(v.toTime))
      ) {
        return $msg.warning("From time cannot be later than to time");
      }
      let fromTime = formatTime(v.fromTime, v.formNumber);
      let toTime = formatTime(v.toTime, v.toNumber);
      v.start = fromTime;
      v.end = toTime;
    }

    const store = useStore();
    const QL = gql`
      query ($page: Int, $limit: Int) {
        users(page: $page, limit: $limit, committer: false) {
          items {
            username
            name
            id
            canLogin
            isAdmin
          }
          total
        }
      }
    `;

    const committer_id = localStorage.getItem("committer_id");

    const { result: committersRes, onResult: onCommittersRes } = useQuery(
      QL,
      () => ({ limit: 99 }),
      { enabled: !committer_id }
    );

    const id =
      committer_id ||
      useResult(
        committersRes,
        0,
        (data) =>
          data.committers.find((e) => e.name == store.state.userInfo.name).id
      );

    onCommittersRes((res) => {
      if (res.data) {
        let id = res.data.committers.find(
          (e) => e.name == store.state.userInfo.name
        ).id;
        localStorage.setItem("committer_id", id);
      }
    });

    const startingView = computed(() => {
      switch (v.dimension) {
        case "year":
        case "quarter":
        case "week":
          return "year";
        case "month":
          return "month";
        default:
          return "day";
      }
    });

    const QUERY_ISSUES = gql`
      query ($committerId: Int, $timeDimensions: String, $time: [String]) {
        issuesByCommitterId(
          committerId: $committerId
          timeDimensions: $timeDimensions
          time: $time
        ) {
          onTimeCount
          advanceCount
          advanceTime
          overdueCount
          overdueTime
          postponeCount
          postponeNum
          postponeTime
          timeDimensions
          time
        }
      }
    `;

    const {
      result,
      onResult,
      loading: listloading,
      onError,
    } = useQuery(QUERY_ISSUES, () => ({
      committerId: Number(committer_id ? committer_id : id.value),
      timeDimensions: v.dimension,
      time: [v.start, v.end],
    }));
    const list = useResult(result, null, (data) => data.issuesByCommitterId);

    onResult((queryResult) => {
      if (!queryResult.data) return;
      console.log(queryResult.data);
    });

    onError((error) => {
      $msg.error("Failed to fetch data");
    });
    const timeformat = computed(() => {
      switch (v.dimension) {
        case "year":
        case "quarter":
        case "week":
          return "yyyy";
        case "month":
          return "yyyy-MM";
        default:
          return "yyyy-MM-dd";
      }
    });

    return {
      ...toRefs(v),
      dimensionList: ["year", "quarter", "month", "week", "Milestone"],
      id,
      startingView,
      timeformat,
      listloading,
      list,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
</style>
